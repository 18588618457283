import ScrollReveal from 'scrollreveal';

window.sr = ScrollReveal();
	
sr.reveal('.menu-teaser:nth-child(even) .menu-teaser-btn, .contact-form, .location-info, .gallery', {
	duration: 600,
	distance: '50px',
	easing: 'ease-in-out',
	origin: 'bottom',
	viewFactor: 0.5
});

sr.reveal('.menu-teaser:nth-child(odd) .menu-teaser-btn, .section-title, .google-map',{
	duration: 600,
	distance: '50px',
	easing: 'ease-in-out',
	origin: 'top',
	viewFactor: 0.5
});
