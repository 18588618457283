import anime from 'animejs';
import throttle from 'lodash/throttle';

const pageHeight = document.body.clientHeight;
const noodleInitHeight = document.querySelector('.scroll-indicator .noodles').clientHeight;

const debouncedFunction = throttle(() => {
  let noodleToPageRatio = window.scrollY / pageHeight;
  let newNoodleHeight = noodleInitHeight - (noodleInitHeight * noodleToPageRatio) + 'px';

  anime({
    targets: '.noodles',
    height: newNoodleHeight,
    easing: 'easeOutQuad',
    duration: 800
  });
},250);

window.onscroll = () => {
 debouncedFunction();
};