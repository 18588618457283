
import anime from 'animejs';

$('#sides .selection-container li, #mains .selection-container li, #drinks .selection-container li').click(function(e){
	e.preventDefault();

	$('.selection-container li .svg').removeClass('active');
	$(this).children('svg').addClass('active');
	
	var index = $('.selection-container li').index(this);
	$('.menu-items-container').removeClass('active');
	$('.menu-items-container').eq(index).addClass('active');

});
