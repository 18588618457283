import Siema from 'siema';

let currentIndex = 0;

$('.menu-teaser-btn').click(function (event) {
	currentIndex = this.getAttribute('data-index');
	var slide = currentIndex;

	$('.scroll-indicator').removeClass('d-md-flex');
	$('.selection-container li:first-child .svg').addClass('active');
	$('.menu-content .menu-items-container:first-of-type').eq(currentIndex).addClass('active');
	$('html, body').addClass('noScroll');

	setTimeout(function() {
		$('.modal-backdrop').css('display','none');
	}, 25);
})

let siema;

$('#menuModal').on('shown.bs.modal', function () {
	let slides = document.querySelectorAll('.menu-content .menu-items-container:first-of-type');
	console.log(slides);
	function printSlideIndex() {
		slides.forEach(eachSlide => eachSlide.classList.remove('active'));
		slides[this.currentSlide].classList.add('active');
	}

	siema = new Siema({
		selector: '.menu-container',

		duration: 200,
		easing: 'ease-out',
		perPage: 1,

		startIndex: currentIndex,
		draggable: false,
		loop: true,
		rtl: false,
		onChange: printSlideIndex,

		onInit: () => {
			printSlideIndex,
			$('.menu-container').css('opacity', '1');
		}
	})

	$('.control-top').click(function () {
		$('.menu-items-container').removeClass('active');
		siema.next();
		resetMenuItem();
	 })
	
	 $('.control-bottom').click(function () {
		$('.menu-items-container').removeClass('active');
		siema.prev();
		resetMenuItem();
	 })

 
	function resetMenuItem() {
		$('.selection-container li .svg').removeClass('active');
		$('.selection-container li:first-child .svg').addClass('active');
		$('.menu-container').css('height','0');
		setTimeout(function() {
			$('.menu-container').css('height','auto');
		}, 50);
	}

})

// When modal closed
$('#menuModal').on('hidden.bs.modal', function () {
	siema.destroy(true, function() {
		$('.menu-container').css('opacity', '0');
		$('.scroll-indicator').addClass('d-md-flex');
		$('.selection-container li .svg, .menu-items-container').removeClass('active');
		$('html, body').removeClass('noScroll');
	});
});