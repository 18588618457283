import $ from 'jquery';
import __ from 'bootstrap-datepicker';

import validate from 'validate.js'
import axios from 'axios'

$('#subjectSelect').val("");	
$('#subjectSelect').change(function(){
	if ($(this).val()=='Booking'){
		$('.form-booking-container').addClass('d-flex');
	}
	else {
		$('.form-booking-container').removeClass('d-flex');	
		$('.form-booking-container input[type=text] , .form-booking-container select').val("");	
	}   
});


// popup close button
$('.popup-close').click(function () {
	location.reload();
});


// Datepicker
$('.input-group.date').datepicker({
	format: "dd/mm/yy",
	autoclose: true,
	todayHighlight: true
});


// Validation
const constraints = {
	firstname: {
		presence: {
			allowEmpty: false
		},
	},

	email: {
		email: true,
		presence: {
			allowEmpty: false
		}
	},

	contactno: {
		numericality: true,
		presence: {
			allowEmpty: false
		}
	},

	subject: {
		presence: {
			allowEmpty: false
		}
	},

	remarks: {
		presence: {
			allowEmpty: false
		}
	},

	receive_updates: {
		presence: {
			allowEmpty: true
		}
	},

	pdpa: {
		inclusion: [1]
	}
}

const bookingConstraints = {
	booking_pax: {
		presence: {
			allowEmpty: false
		}
	},

	booking_date: {
		presence: {
			allowEmpty: false
		}
	},

	booking_time: {
		presence: {
			allowEmpty: false
		}
	}
}

// Selecting all fields
const allFormFields = document.querySelectorAll('form input, form textarea, form select');	


'click' // submit button
function validateFormAndSubmit() {
	const fields = {};	

	// add to fields object
	allFormFields.forEach((field) => {
		// get field name
		const name = field.name

		// skip if no name
		if(!name) return

		// assign field to fields object
		if(field.type === 'checkbox') { // if checkbox input exists
			fields[name] = +field.checked // checkbox selected
		} else {
			fields[name] = field.value // if no checkbox, use this and no need if statement
		}
	})

	// check if booking function
	const checkIfBooking = () => {
		const subjectField = document.querySelector('#subjectSelect')
		return subjectField.value === 'bookings'
	}

	// set constraints to use
	const constraintsToUse = checkIfBooking()
		? { ...constraints , ...bookingConstraints }
		: constraints;

	// console.log(checkIfBooking(), constraintsToUse)

	// validation
	// check for errors
	const errors = validate(fields, constraintsToUse)

	// remove has-error from all fields
	allFormFields.forEach(field => field.classList.remove('has-error'))

	// console.log(errors)

	if(errors) {
		// loop through all errors
		Object.keys(errors).forEach((error) => {
			// get relevant field
			const relevantField = Array
				.from(allFormFields)
				.find(field => field.name === error)

			// add has-error
			relevantField.classList.add('has-error')
		})
	}else {
		// console.log('no errors!')
		document.getElementById('loadAnimation').style.display = "block";
	}

	// if errors, don't proceed
	if(errors) return

	const formData = new FormData();
	Object.keys(fields).forEach(key => formData.set(key, fields[key]));

	// submit form
	axios({
		method: 'post',
		url: '/api/portlet.php',

		data: formData
	})
		.then(response => {
			// response for success
			// console.log(response)
			document.getElementById('confirmation-popup').classList.add('show');
			document.getElementById('form-success').style.display = "block";
			$('html, body').addClass('noScroll');
		})
		.catch(error => {
			// response for errors
			// console.log('error is' + error)
			document.getElementById('confirmation-popup').classList.add('show');
			document.getElementById('form-error').style.display = "block";
			$('html, body').addClass('noScroll');
		})
}

$('.btn-primary[type="submit"]').click(validateFormAndSubmit)

$('form').on('submit', (e) => e.preventDefault()) // prevents page from reloading