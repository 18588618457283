// import 'intersection-observer';
import './vendor/jquery.waypoints';
import Rellax from 'rellax';
import SmoothScroll from 'smooth-scroll';
import SidebarMenuEffects from './vendor/sidebarEffects';
import './components/bookingForm';
import './components/foodMenuSelection';
import './components/imageGallery';
import './components/inlineSVG';
import './components/menuBar';
import './components/menuCategorySlider';
import './components/pageScrollIndicator';
import './components/scrollReveal';
import './components/windowLoadAnime';

$(document).ready(function () {
	// INITIATE SMOOTH SCROLLING
	var scroll = new SmoothScroll('a[href*="#"]');

	// Nav colour waypoint
	var waypoint = new Waypoint({
		element: document.getElementsByClassName('scroll-colour-change'),
		handler: function (direction) {
			if (direction === 'down') {
				$('.scroll-indicator').addClass('svg-green');
			}
			else {
				$('.scroll-indicator').removeClass('svg-green');
			}
		},
		offset: '58%'
	});

	
	// INITIATE RELLAX
	var rellax = new Rellax('.rellax-element img', {
		center: true,
		round: true,
		vertical: true,
		horizontal: false
	});


	// BODY NO SCROLL WHEN MODAL OPEN
	$('.modal-open').click(function(e){
		$('html, body').addClass('noScroll');
	});

	// BODY SCROLL WHEN MODAL CLOSE
	$('.modal-close, .modal-policy').click(function(e){
		$('html, body').removeClass('noScroll');
	});

});
