import anime from 'animejs';

$(window).bind('load', function() {
	setTimeout(function() {
		$('#loader').fadeOut(300);
		$('body, html').removeClass('noScroll');
	}, 300);

	setTimeout(function() {
		$('#loader-wrapper').addClass('hide');
		$('body').css('overflow','auto');
	}, 600);

	setTimeout(function() {
		$('#loader-wrapper').addClass('d-none');
	}, 800);

	anime({
		targets: '.logo',
		easing: 'easeOutQuint',
		translateX: 20,
		opacity: [0, 1],
		delay: 600,
		duration: 600
	});

	anime({
		targets: '.navmenu-btn',
		easing: 'easeOutQuint',
		translateX: -20,
		opacity: [0, 1],
		duration: 300,
		delay: 600
	});

	anime({
		targets: '.nav-links',
		easing: 'easeOutQuint',
		translateX: -20,
		opacity: [0, 1],
		duration: 1000,
		delay: 700
	});

	anime({
		targets: '.sticky-nav > .social-media',
		easing: 'easeOutQuint',
		translateX: -20,
		opacity: [0, 1],
		duration: 2000,
		delay: 800
	});

	anime({
		targets: '.landing-content',
		easing: 'easeOutQuint',
		translateY: -20,
		opacity: [0, 1],
		duration: 1000,
		delay: 1000
	});

});